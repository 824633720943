import Carousel1 from "../../../images/workwithusimages/Carousel1.webp";
import Carousel13 from "../../../images/workwithusimages/Carousel13.webp";
import Carousel9 from "../../../images/workwithusimages/Carousel9.webp";
import Carousel10 from "../../../images/workwithusimages/Carousel10.webp";
import Carousel14 from "../../../images/workwithusimages/Carousel14.webp";
import Carousel12 from "../../../images/workwithusimages/Carousel12.webp";




export const mobileData = [
  {
    image: Carousel1,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel9,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel10,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel13,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel14,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel12,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
];
