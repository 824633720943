import Carousel1 from "../../../images/workwithusimages/Carousel1.webp";
import Carousel13 from "../../../images/workwithusimages/Carousel13.webp";
import Carousel8 from "../../../images/workwithusimages/Carousel8.webp";
import Carousel9 from "../../../images/workwithusimages/Carousel9.webp";
import Carousel10 from "../../../images/workwithusimages/Carousel10.webp";
import Carousel7 from "../../../images/workwithusimages/Carousel7.webp";
import Carousel14 from "../../../images/workwithusimages/Carousel14.webp";
import Carousel11 from "../../../images/workwithusimages/Carousel11.webp";
import Carousel12 from "../../../images/workwithusimages/Carousel12.webp";
import Carousel2 from "../../../images/workwithusimages/Carousel2.webp";
import Carousel3 from "../../../images/workwithusimages/Carousel3.webp";
import Carousel4 from "../../../images/workwithusimages/Carousel4.webp";
import Carousel5 from "../../../images/workwithusimages/Carousel5.webp";
import Carousel6 from "../../../images/workwithusimages/Carousel6.webp";
import TeamPhoto from "../../../images/workwithusimages/TeamPhoto.webp";



export const teamData = [
  {
    image: Carousel1,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel7,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel8,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel9,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel10,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel13,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: TeamPhoto,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel14,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel11,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel12,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel2,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel3,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel4,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel5,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
  {
    image: Carousel6,
    alt: "Kraftshala Team Image",
    title: "Life at Kraftshala",
  },
];
