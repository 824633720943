import React, { useEffect, useState } from "react";
// import { StaticImage } from "gatsby-plugin-image";
// import { Link } from "gatsby";
// import { jobData } from "./data";

import "./styles.scss";
import Select from "../../Forms/Common/Select/select";

function JobOpenings() {

  const [jobOpenings, setJobOpenings] = useState([]);
  const [deptFilterOpts, setDeptFilterOpts] = useState([]);
  const [selectedDeptFilter, setDeptFilter] = useState("");

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchJobOpennings();
  }, []);

  const fetchJobOpennings = async () => {
    try {
      setLoading(true);
      const url = `https://api.turbohire.co/api/careerpagejobs`;
      const data = {};
      const response = await fetch(url, {
        method: "POST",
        // mode: "cors", 
        cache: "no-cache", 
        // credentials: "same-origin", 
        headers: {
          "accept": "application/json, text/plain, */*",
          "cache-control": "no-cache",
          "content-type": "application/json;",
          // "x-api-key": "A0DE5032-60B3-4732-8EB9-0510C67032CD",
          "x-api-key": "C5A91460-9040-4C50-B4CA-5C9623BB824E",
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();

      if(jsonData?.Result) {
        let newDeptFilterOpts = jsonData.Result.map(job => {
          return job.Department;
        });

        newDeptFilterOpts.unshift("All Departments");
        newDeptFilterOpts = new Set(newDeptFilterOpts);
        setDeptFilterOpts(Array.from(newDeptFilterOpts));
        setJobOpenings(jsonData.Result);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getValuesFn = (data) => {
    console.log(data);
    setDeptFilter(data?.value ?? "");
  };

  const getExperience = (exp) => {
    if(exp?.MinExp >=0 && exp?.MaxExp >=0) {
      return `Exp: ${exp.MinExp} - ${exp.MaxExp} yrs`;
    }
  };

  // const activeJobs = jobData
  //   .slice(0)
  //   .reverse()
  //   .filter((data) => {
  //     return data.is_active;
  //   });

  return (
    <div className="wwu-openings" id="jobOpenings">
      <h2 className="wwu-openings-heading">
        {/* <a
          href="https://kraftshala.freshteam.com/jobs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Current Job Openings &rarr;
        </a> */}
        Current Job Openings
      </h2>
      {isLoading && <div class="current-openings-loader" />}
      {!isLoading &&
        <>
          <div className="current-openings-filters">
            <Select
              id="current-openings-dept-filter"
              name="dept_filter"
              placeholder="Filter By Department"
              options={deptFilterOpts}
              getValuesFn={getValuesFn}
              btnClicked={false}
              value={selectedDeptFilter} 
            />
          </div>
          <section className="current-opennings-grid">
            {!jobOpenings.length && <h2 className="no-offer-msg">Sorry, Currently No Job Openings Found</h2>}
            {jobOpenings.filter(job => (selectedDeptFilter === "" || selectedDeptFilter === "All Departments") ? true:  (job.Department === selectedDeptFilter)).map((job) => {
              return (
                <div key={job.JobId} className="job-card">
                  <h3 className="job-card-heading">{job.JobTitle}</h3>
                  <p style={{margin:0}} ><h5 className="job-card-desc">Department: {job.Department} | {getExperience(job.Experience)}</h5></p>
                  <p className="job-card-desc" dangerouslySetInnerHTML={{__html: job.JobDescription ?? ""}}>
                  </p>
                  <button 
                    className="job-card-apply-btn"
                    onClick={() => window.open(job.ApplyUrl, "_blank")}
                  >
                    Apply Now
                  </button>
                </div>
              );
            })}
          </section>
        </>
      }
    </div>
  );
}

export default JobOpenings;
