import usercentricimg from "../../../images/workwithusimages/Vector.png";
import problemskillimg from "../../../images/workwithusimages/problemskillimg.png";
import learningagility from "../../../images/workwithusimages/learningagility.png";
import practicaloptimist from "../../../images/workwithusimages/practicaloptimist.png";
import ambitionimg from "../../../images/workwithusimages/ambitionimg.png";
import Consistencyimg from "../../../images/workwithusimages/Consistencyimg.png";
import Realimg from "../../../images/workwithusimages/Realimg.png";


export const cardData = [
  {
    image: usercentricimg,
    title: "USER CENTRIC MINDSET",
    alt: "User-Centric Mindset at Kraftshala",
    description: `We seek a <span class="text-yellow">user centric mindset.</span>
    People who start with the user and work backward, and take every
    opportunity to add value to their customers/ partners fit right
    in.`,
  },
  {
    image: problemskillimg,
    title: "PROBLEM SOLVING SKILLS",
    alt: "Kraftshala requires PROBLEM SOLVING SKILLS",
    description: `We value <span class="text-yellow">problem solving skills.</span>
    We look at problems objectively, solve for root causes, make
    decisions for long term good instead of short term gain, and don’t
    let processes get in the way of value addition.`,
  },
  {
    image: learningagility,
    title: "LEARNING AGILITY",
    alt: "LEARNING AGILITY with Kraftshala",
    description: `We look for <span class="text-yellow">learning agility.</span> We
    are a team of learners who like to read, talk to experts, and seek
    feedback to be better today than we were yesterday.`,
  },
  {
    image: practicaloptimist,
    title: "PRACTICAL OPTIMISTS",
    alt: "Kraftshala wants PRACTICAL OPTIMISTS",
    description: `We are <span class="text-yellow">practical optimists.</span> Our
    team strongly believes in a better future & we enjoy playing our
    part to make that future a reality.`,
  },
  {
    image: ambitionimg,
    title: "AMBITION AND COURAGE",
    alt: "Kraftshala values AMBITION AND COURAGE",
    description: `We respect <span class="text-yellow">ambition & courage</span> to
    take on large goals. Self starters strengthen the backbone of our
    team.`,
  },
  {
    image: Consistencyimg,
    title: "CONSISTENCY AND RELIABILITY",
    alt: "Kraftshala values  CONSISTENCY AND RELIABILITY",
    description: `We expect
    <span class="text-yellow">consistency and reliability.</span>
    Predictability just makes everyone’s life a lot smoother.`,
  },
  {
    image: Realimg,
    title: "REAL, OPEN AND DIRECT",
    alt: "Kraftshala wants REAL, OPEN AND DIRECT people",
    description: `We like people who are
    <span class="text-yellow">real, open and direct</span> in
    communication, while still being empathetic.`,
  },
];
